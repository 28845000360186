<template>
    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h1 class="text-3xl text-center text-gray-900">{{ $tc('reset_your_password', 1) }}</h1>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form @submit.prevent="resetPassword">
                    <fieldset :disabled="isSubmitting" class="space-y-6">
                        <Input :label="$tc('password', 1)" type="password" id="password" v-model="password" :error="validationErrors.password" />
                        <Input :label="$tc('confirm_password', 1)" type="password" id="confirm_password" v-model="confirm_password" :error="validationErrors.confirm_password" />

                        <SubmitButton class="w-full" :label="$tc('reset_password', 1)" :submittingLabel="$tc('resetting_password', 1)" :isSubmitting="isSubmitting" />

                        <div class="text-sm text-center">
                            <router-link class="font-medium text-indigo-600 hover:text-indigo-500" :to="{ name: 'login' }">{{ $tc('back_to_login', 1) }}</router-link>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                password: '',
                confirm_password: '',
                isSubmitting: false,
                validationErrors: {},
            };
        },
        methods: {
            handleResetPassword() {
                // Validate
            },
        },
    };
</script>
